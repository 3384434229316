import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueVideoPlayer from 'vue-video-player';

import 'videojs-contrib-hls'

require('video.js/dist/video-js.css')

require('vue-video-player/src/custom-theme.css')

Vue.config.productionTip = false

Vue.use(VueVideoPlayer);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
